// Generated by Framer (16060ad)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {CZguZvnWw: {hover: true}};

const cycleOrder = ["CZguZvnWw"];

const variantClassNames = {CZguZvnWw: "framer-v-76j5ns"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "CZguZvnWw", image: Ir8ppN4pC = {src: new URL("assets/512/1H13a6svlhvSccvHTIw2iV30Oss.png", import.meta.url).href, srcSet: `${new URL("assets/512/1H13a6svlhvSccvHTIw2iV30Oss.png", import.meta.url).href} 409w, ${new URL("assets/1H13a6svlhvSccvHTIw2iV30Oss.png", import.meta.url).href} 700w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CZguZvnWw", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-LRiDO", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-76j5ns", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"CZguZvnWw"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"CZguZvnWw-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 875, intrinsicWidth: 700, pixelHeight: 875, pixelWidth: 700, ...toResponsiveImage(Ir8ppN4pC)}} className={"framer-1bxp2dx"} data-framer-name={"team_raphael"} layoutDependency={layoutDependency} layoutId={"XVMZSZho3"} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-LRiDO [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-LRiDO .framer-15r4qpb { display: block; }", ".framer-LRiDO .framer-76j5ns { height: 438px; overflow: hidden; position: relative; width: 350px; }", ".framer-LRiDO .framer-1bxp2dx { aspect-ratio: 0.8 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 438px); left: 0px; overflow: visible; position: absolute; top: 0px; width: 100%; }", ".framer-LRiDO .framer-v-76j5ns .framer-76j5ns { cursor: pointer; }", ".framer-LRiDO.framer-v-76j5ns.hover .framer-1bxp2dx { height: var(--framer-aspect-ratio-supported, 625px); left: -75px; right: -75px; top: -94px; width: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 438
 * @framerIntrinsicWidth 350
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"CEQgHYUbs":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Ir8ppN4pC":"image"}
 */
const FramerJ_Ry00hm9: React.ComponentType<Props> = withCSS(Component, css, "framer-LRiDO") as typeof Component;
export default FramerJ_Ry00hm9;

FramerJ_Ry00hm9.displayName = "team-raphael";

FramerJ_Ry00hm9.defaultProps = {height: 438, width: 350};

addPropertyControls(FramerJ_Ry00hm9, {Ir8ppN4pC: {__defaultAssetReference: "data:framer/asset-reference,1H13a6svlhvSccvHTIw2iV30Oss.png?originalFilename=team-raphael.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerJ_Ry00hm9, [])